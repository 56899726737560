import { css } from '@emotion/react'
import { ComponentPropsWithoutRef, ReactNode } from 'react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

export type NavVariantOptions = 'OVERLAY' | 'ABOVE' | 'CORNER'

interface Props extends ComponentPropsWithoutRef<'div'> {
  navLink?: ReactNode
  onClickBack: () => void
  backDisabled: boolean
  onClickForward: () => void
  forwardDisabled: boolean
  navVariant?: NavVariantOptions
}

export const CarouselNav = ({
  navLink,
  onClickBack,
  backDisabled,
  onClickForward,
  forwardDisabled,
  navVariant,
  ...props
}: Props): JSX.Element => {
  const styles = {
    nav: css`
      ${navVariant === 'OVERLAY' &&
      css`
        display: flex;
        justify-content: space-between;
        position: absolute;
        pointer-events: none;
        z-index: 2;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
      `}
      ${navVariant === 'ABOVE' &&
      css`
        position: relative;
        margin-bottom: 1rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      `}
      ${navVariant === 'CORNER' &&
      css`
        position: absolute;
        z-index: 3;
        bottom: 1rem;
        left: 1rem;
        display: flex;
        grid-gap: 0.25rem;
        ${mq().s} {
          bottom: 0.5rem;
          left: 0.5rem;
        }
      `}
    `,
    buttonWrapper: css`
      ${navVariant === 'OVERLAY' &&
      css`
        svg {
          height: 50%;
        }
      `}
    `,
    scrollButton: css`
      display: flex;
      flex: none;
      align-items: center;
      justify-content: center;
      svg {
        position: relative;
        width: auto;
        overflow: visible;
        transform: scale3d(0.999, 0.999, 1);
        transition: transform 400ms cubic-bezier(0.33, 3, 0.25, 0.5);
        polyline {
          fill: transparent;
          stroke-width: 2;
          stroke: var(--arrow-color, ${colors.gray20});
          transition: all 300ms ease;
        }
      }
      @media (hover: hover) {
        &:hover {
          svg {
            transform: scale3d(1.125, 1.125, 1);
            polyline {
              stroke: var(--arrow-color-hover, ${colors.red});
            }
          }
        }
      }
      ${navVariant === 'OVERLAY' &&
      css`
        pointer-events: all;
        width: var(--margin);
        padding: 1rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 100%;
          max-width: 5rem;
          height: auto;
        }
        ${mq().s} {
          padding: 0.375rem;
          width: calc(0.25rem + var(--margin));
          svg {
            height: calc(2 * var(--margin));
          }
        }
      `}
      ${navVariant === 'ABOVE' &&
      css`
        width: 3em;
        height: 3em;
        justify-content: center;
        svg {
          height: 75%;
          left: auto !important;
          right: auto !important;
        }
      `}
      ${navVariant === 'CORNER' &&
      css`
        width: 4rem;
        height: 4rem;
        justify-content: center;
        background: #00000066;
        backdrop-filter: blur(3px);
        --arrow-color: #fff;
        --arrow-color-hover: ${colors.redLight};
        svg {
          height: 75%;
          left: auto !important;
          right: auto !important;
        }
        ${mq().s} {
          width: 3rem;
          height: 3rem;
        }
      `}
      &[data-back] {
        svg {
          margin-right: 10%;
        }
      }
      &[data-forward] {
        svg {
          margin-left: 10%;
        }
      }
      &[disabled] {
        svg polyline {
          opacity: 0.125;
        }
        cursor: default;
        pointer-events: none;
      }
    `,
    link: css`
      flex: none;
      > a,
      > button {
        font-size: var(--fs-15);
        margin: 0 0.67em;
        color: var(--link-color, ${colors.red});
        ${mq().s} {
          font-size: var(--fs-13);
          margin-right: 0.75em;
        }
        &:hover {
          @media (hover: hover) {
            color: var(--link-color-hover, ${colors.redDark});
          }
        }
      }
    `,
  }
  return (
    <nav
      css={styles.nav}
      {...props}
    >
      {navLink && <div css={styles.link}>{navLink}</div>}
      <div
        css={styles.buttonWrapper}
        data-back
      >
        <button
          css={styles.scrollButton}
          onClick={onClickBack}
          onKeyPress={onClickBack}
          aria-label="scroll back"
          data-back
          disabled={backDisabled}
        >
          <svg
            width="24px"
            height="48px"
            viewBox="0 0 24 48"
            preserveAspectRatio="none"
          >
            <polyline
              points="22.5 45.5 1 24 22.5 2.5"
              vectorEffect="non-scaling-stroke"
            />
          </svg>
        </button>
      </div>
      <div
        css={styles.buttonWrapper}
        data-forward
      >
        <button
          css={styles.scrollButton}
          onClick={onClickForward}
          onKeyPress={onClickForward}
          aria-label="scroll forward"
          data-forward
          disabled={forwardDisabled}
        >
          <svg
            width="24px"
            height="48px"
            viewBox="0 0 24 48"
            preserveAspectRatio="none"
          >
            <polyline
              points="1 45.5 22.5 24 1 2.5"
              vectorEffect="non-scaling-stroke"
            />
          </svg>
        </button>
      </div>
    </nav>
  )
}
